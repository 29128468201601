/*!
 KeyTable 2.1.2
 ©2009-2016 SpryMedia Ltd - datatables.net/license
*/
(function(e){"function"===typeof define&&define.amd?define(["jquery","datatables.net"],function(k){return e(k,window,document)}):"object"===typeof exports?module.exports=function(k,g){k||(k=window);if(!g||!g.fn.dataTable)g=require("datatables.net")(k,g).$;return e(g,k,k.document)}:e(jQuery,window,document)})(function(e,k,g,n){var h=e.fn.dataTable,l=function(a,b){if(!h.versionCheck||!h.versionCheck("1.10.8"))throw"KeyTable requires DataTables 1.10.8 or newer";this.c=e.extend(!0,{},h.defaults.keyTable,
l.defaults,b);this.s={dt:new h.Api(a),enable:!0,focusDraw:!1};this.dom={};var d=this.s.dt.settings()[0],c=d.keytable;if(c)return c;d.keytable=this;this._constructor()};e.extend(l.prototype,{blur:function(){this._blur()},enable:function(a){this.s.enable=a},focus:function(a,b){this._focus(this.s.dt.cell(a,b))},focused:function(a){if(!this.s.lastFocus)return!1;var b=this.s.lastFocus.index();return a.row===b.row&&a.column===b.column},_constructor:function(){this._tabInput();var a=this,b=this.s.dt,d=e(b.table().node());
"static"===d.css("position")&&d.css("position","relative");e(b.table().body()).on("click.keyTable","th, td",function(){if(!1!==a.s.enable){var c=b.cell(this);c.any()&&a._focus(c,null,!1)}});e(g).on("keydown.keyTable",function(b){a._key(b)});if(this.c.blurable)e(g).on("click.keyTable",function(c){e(c.target).parents(".dataTables_filter").length&&a._blur();e(c.target).parents().filter(b.table().container()).length||e(c.target).parents("div.DTE").length||a._blur()});if(this.c.editor)b.on("key.keyTable",
function(b,c,d,e,g){a._editor(d,g)});if(b.settings()[0].oFeatures.bStateSave)b.on("stateSaveParams.keyTable",function(b,c,d){d.keyTable=a.s.lastFocus?a.s.lastFocus.index():null});b.on("xhr.keyTable",function(){if(!a.s.focusDraw){var c=a.s.lastFocus;c&&(a.s.lastFocus=null,b.one("draw",function(){a._focus(c)}))}});b.on("destroy.keyTable",function(){b.off(".keyTable");e(b.table().body()).off("click.keyTable","th, td");e(g.body).off("keydown.keyTable").off("click.keyTable")});var c=b.state.loaded();if(c&&
c.keyTable)b.one("init",function(){var a=b.cell(c.keyTable);a.any()&&a.focus()});else this.c.focus&&b.cell(this.c.focus).focus()},_blur:function(){if(this.s.enable&&this.s.lastFocus){var a=this.s.lastFocus;e(a.node()).removeClass(this.c.className);this.s.lastFocus=null;this._emitEvent("key-blur",[this.s.dt,a])}},_columns:function(){var a=this.s.dt,b=a.columns(this.c.columns).indexes(),d=[];a.columns(":visible").every(function(a){-1!==b.indexOf(a)&&d.push(a)});return d},_editor:function(a,b){var d=
this.s.dt,c=this.c.editor;b.stopPropagation();13===a&&b.preventDefault();c.inline(this.s.lastFocus.index());var f=e("div.DTE input, div.DTE textarea");f.length&&f[0].select();d.keys.enable("navigation-only");d.one("key-blur.editor",function(){c.displayed()&&c.submit()});c.one("close",function(){d.keys.enable(!0);d.off("key-blur.editor")})},_emitEvent:function(a,b){this.s.dt.iterator("table",function(d){e(d.nTable).triggerHandler(a,b)})},_focus:function(a,b,d){var c=this,f=this.s.dt,i=f.page.info(),
m=this.s.lastFocus;if(this.s.enable){if("number"!==typeof a){var j=a.index(),b=j.column,a=f.rows({filter:"applied",order:"applied"}).indexes().indexOf(j.row);i.serverSide&&(a+=i.start)}if(-1!==i.length&&(a<i.start||a>=i.start+i.length))this.s.focusDraw=!0,f.one("draw",function(){c.s.focusDraw=!1;c._focus(a,b)}).page(Math.floor(a/i.length)).draw(!1);else if(-1!==e.inArray(b,this._columns())){i.serverSide&&(a-=i.start);i=f.cell(":eq("+a+")",b,{search:"applied"});if(m){if(m.node()===i.node())return;
this._blur()}m=e(i.node());m.addClass(this.c.className);if(d===n||!0===d)this._scroll(e(k),e(g.body),m,"offset"),d=f.table().body().parentNode,d!==f.table().header().parentNode&&(d=e(d.parentNode),this._scroll(d,d,m,"position"));this.s.lastFocus=i;this._emitEvent("key-focus",[this.s.dt,i]);f.state.save()}}},_key:function(a){if(this.s.enable&&!(0===a.keyCode||a.ctrlKey||a.metaKey||a.altKey)){var b=this.s.lastFocus;if(b){var d=this,c=this.s.dt;if(!(this.c.keys&&-1===e.inArray(a.keyCode,this.c.keys)))switch(a.keyCode){case 9:this._shift(a,
a.shiftKey?"left":"right",!0);break;case 27:this.s.blurable&&!0===this.s.enable&&this._blur();break;case 33:case 34:a.preventDefault();var f=c.cells({page:"current"}).nodes().indexOf(b.node());c.one("draw",function(){var a=c.cells({page:"current"}).nodes();d._focus(c.cell(f<a.length?a[f]:a[a.length-1]))}).page(33===a.keyCode?"previous":"next").draw(!1);break;case 35:case 36:a.preventDefault();b=c.cells({page:"current"}).indexes();this._focus(c.cell(b[35===a.keyCode?b.length-1:0]));break;case 37:this._shift(a,
"left");break;case 38:this._shift(a,"up");break;case 39:this._shift(a,"right");break;case 40:this._shift(a,"down");break;default:!0===this.s.enable&&this._emitEvent("key",[c,a.keyCode,this.s.lastFocus,a])}}}},_scroll:function(a,b,d,c){var c=d[c](),f=d.outerHeight(),d=d.outerWidth(),e=b.scrollTop(),g=b.scrollLeft(),j=a.height(),a=a.width();c.top<e&&b.scrollTop(c.top);c.left<g&&b.scrollLeft(c.left);c.top+f>e+j&&f<j&&b.scrollTop(c.top+f-j);c.left+d>g+a&&d<a&&b.scrollLeft(c.left+d-a)},_shift:function(a,
b,d){var c=this.s.dt,f=c.page.info(),i=f.recordsDisplay,g=this.s.lastFocus,j=this._columns();if(g){var h=c.rows({filter:"applied",order:"applied"}).indexes().indexOf(g.index().row);f.serverSide&&(h+=f.start);c=c.columns(j).indexes().indexOf(g.index().column);f=j[c];"right"===b?c>=j.length-1?(h++,f=j[0]):f=j[c+1]:"left"===b?0===c?(h--,f=j[j.length-1]):f=j[c-1]:"up"===b?h--:"down"===b&&h++;0<=h&&h<i&&-1!==e.inArray(f,j)?(a.preventDefault(),this._focus(h,f)):!d||!this.c.blurable?a.preventDefault():this._blur()}},
_tabInput:function(){var a=this,b=this.s.dt,d=null!==this.c.tabIndex?this.c.tabIndex:b.settings()[0].iTabIndex;if(-1!=d)e('<div><input type="text" tabindex="'+d+'"/></div>').css({position:"absolute",height:1,width:0,overflow:"hidden"}).insertBefore(b.table().node()).children().on("focus",function(){a._focus(b.cell(":eq(0)","0:visible",{page:"current"}))})}});l.defaults={blurable:!0,className:"focus",columns:"",editor:null,focus:null,keys:null,tabIndex:null};l.version="2.1.2";e.fn.dataTable.KeyTable=
l;e.fn.DataTable.KeyTable=l;h.Api.register("cell.blur()",function(){return this.iterator("table",function(a){a.keytable&&a.keytable.blur()})});h.Api.register("cell().focus()",function(){return this.iterator("cell",function(a,b,d){a.keytable&&a.keytable.focus(b,d)})});h.Api.register("keys.disable()",function(){return this.iterator("table",function(a){a.keytable&&a.keytable.enable(!1)})});h.Api.register("keys.enable()",function(a){return this.iterator("table",function(b){b.keytable&&b.keytable.enable(a===
n?!0:a)})});h.ext.selector.cell.push(function(a,b,d){var b=b.focused,a=a.keytable,c=[];if(!a||b===n)return d;for(var e=0,g=d.length;e<g;e++)(!0===b&&a.focused(d[e])||!1===b&&!a.focused(d[e]))&&c.push(d[e]);return c});e(g).on("preInit.dt.dtk",function(a,b){if("dt"===a.namespace){var d=b.oInit.keys,c=h.defaults.keys;if(d||c)c=e.extend({},d,c),!1!==d&&new l(b,c)}});return l});
